<script setup lang="ts">

    const props = defineProps<{
        title: string
    }>();

</script>

<template>

    <div class="feature-card">

        <div class="header">
            <slot name="header-icon"></slot>
            <label>{{ props.title }}</label>
        </div>
        <p>
            <slot name="description"></slot>
        </p>
        <div v-if="$slots.note"
            class="note"
        >
            <slot name="note"></slot>
        </div>

    </div>

</template>

<style scoped>

    .feature-card {
        @apply
            bg-white
            border-2
            border-stone-300
            rounded-lg
            p-4
            md:p-8
            flex flex-col justify-start items-start;
    }

    .feature-card > .header {
        @apply
            flex items-center justify-start
            mb-6
            md:mb-8
            grow-0;

        & :deep(svg) {
            @apply
                size-8
                mr-2
                stroke-fss-brand-red;
        }

        & > label {
            @apply
                text-2xl
                font-medium
                text-neutral-700;
        }
    }

    .feature-card > p {
        @apply 
            text-neutral-600
            mb-4
            grow;
    }

    .feature-card > .note {
        @apply
            flex items-center
            grow-0;

        & :deep(svg) {
            @apply
                size-6
                mr-2;
        }

        & :deep(p) {
            @apply
                italic
                text-neutral-500;
        }
    }

</style>